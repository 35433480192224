import React from 'react';
import {getArticleLinks} from "../utils/articleUtil";
import Navigate from "../components/navigate";
import {GatsbyImage} from "gatsby-plugin-image";
import cx from "classnames";

const VideoArticlePreview = props => {
    const className = cx(
        "",//className={`video-article-preview placeholder--teaser-card ${props.customClass ? props.customClass : ''}`}
        props.customClass,
        props.className,
    );
    const headingClass = cx(
        props.headingClass?.includes("text-") ? "" : "text-2xl",
        props.headingClass?.includes("mb-") ? "" : "mb-2",
        "block",
        props.headingClass
    );
    const imageClass = cx(
        props.imageClass?.includes("mb-") ? "" : "mb-4",
        props.imageClass
    );
    const textClass = cx(
        "",
        props.textClass
    );

    const articleLinks = getArticleLinks(props.data);
    const category = articleLinks.category;
    const categoryPath = articleLinks.categoryPath;
    const getReadMore = articleLinks.getReadMore;

    const imageUrl = () => {
        let url = `${props.data.cover.url}?`;
        let qualityGiven = false;
        if (props.imageMeta) {
            const keys = Object.keys(props.imageMeta);
            keys.forEach((key) => {
                url += `${key}=${props.imageMeta[key]}&`;
                !qualityGiven && (qualityGiven = key === 'q')
            });
        }
        if (!qualityGiven) {
            url += 'q=50';
        }
        return url;
    }

    const imageComponent = () => {
        return <figure className={imageClass}>
            <Navigate href={getReadMore()}>
                {
                    props.imageFromUrl
                        ?
                        <img alt={props.data.heading} src={imageUrl()}/>
                        :
                        <GatsbyImage alt={props.data.heading} image={props.data.cover.gatsbyImageData} />
                }
            </Navigate>
        </figure>
    }

    return (
        <div className={className} key={props.data.slug} data-datocms-noindex>
            {!props.noImage && props.data.cover?.url && imageComponent()}
            {!props.noFlag && <Navigate className="block font-bold text-sm mb-2 text-denim" href={categoryPath()}>{category.title}</Navigate>}
            <Navigate className={headingClass} href={getReadMore()}>{props.data.heading}</Navigate>
            {!props.noBody && <>
                <p className={textClass}>{props.data.excerpt}</p>
            </>}
        </div>
    )
}
export default VideoArticlePreview;
